export default {
  isIOS: () => {
    const userAgent = window.navigator.userAgent
    return /iPhone|iPad|iPod/i.test(userAgent)
  },
  isAndroid: () => {
    const userAgent = window.navigator.userAgent
    return /Android/i.test(userAgent)
  },
  isMobile: () => {
    const userAgent = window.navigator.userAgent
    if (/Android/i.test(userAgent)) {
      return true
    } else return /iPhone|iPad|iPod/i.test(userAgent)
  }
}
