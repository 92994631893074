<template>
  <v-layout class="display-table">
    <v-flex class="text-xs-center display-table-cell" style="min-height: 300px;">
      <i class="fas fa-check-circle" style="color: #1976d2;"></i>
      <dl>
        <dt class="display-1 m-y-30">설문 제출이 완료되었습니다</dt>
      </dl>
    </v-flex>
  </v-layout>
</template>


<script>
import DataUtils from '@/utils/data'

export default {
  methods: {
    closeEvent () {
      try {
        if (DataUtils.isMobile()) {
          if (DataUtils.isIOS()) {
            window.webkit.messageHandlers.CommonCallbackHandler.postMessage('func=native_submitTrigger')
          } else {
            window.SubWeb.native_submitTrigger()
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    this.closeEvent()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.display-table{
  display: table;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  &-cell{
    display: table-cell;
    vertical-align: middle;
  }
}
.fa-check-circle{
  font-size: 150px;
}
@media screen and (max-width: $mobile) {
  .fa-check-circle{
    font-size: 90px;
  }
}
</style>
